import React from "react"
import { useForm } from "react-hook-form"
import { graphql } from "gatsby"
import axios from "axios"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  Container,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Grid,
  GridItem,
  Text,
  Link,
  Tag,
} from "@chakra-ui/react"
import SubpageParallaxImageHero from "../components/partials/subpage-parallax-image-hero"
import { motion, useAnimation, useAnimationControls } from "framer-motion"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { BsFillTelephoneFill } from "react-icons/bs"

interface Props {
  data: any
}

const Contact: React.FC<Props> = ({ data }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm()
  const { tel } = data.allContentfulCompanyProfile.edges[0].node

  const btnControls = useAnimationControls()
  const borderControls = useAnimationControls()

  const boxVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, y: 50 },
  }
  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible")
    } else {
      control.start("hidden")
    }
  }, [control, inView])

  const onSubmit = async (values: any) => {
    try {
      const {
        data: { next, ok },
      } = await axios.post("https://formspree.io/f/mzbkvjwn", {
        ...values,
      })

      if (ok) {
        navigate(next)
      }
      reset()
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <Layout>
      <Seo
        title="お問い合わせ"
        description="お問い合わせ"
        image={data.allContentfulSiteImages.edges[0].node.image.url}
      />
      {data.allContentfulSiteImages.edges.map(({ node: siteImage }: any) => (
        <SubpageParallaxImageHero
          url={siteImage.image.url}
          caption={`お問い合わせ`}
          fontFamily="sans"
        />
      ))}
      <Container maxW="container.xl" as={`main`} p={0}>
        <Stack pb={{ base: 8, lg: 24 }} spacing={8} pt={{ base: 4, lg: 24 }}>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
            gap={6}
          >
            <GridItem
              as={motion.p}
              lineHeight={1.8}
              variants={boxVariant}
              ref={ref}
              initial={`hidden`}
              animate={control}
              w="100%"
            >
              <Text
                mt={{ base: 8, lg: 120 }}
                mb={35}
                fontWeight="bold"
                fontSize={16}
                textAlign="center"
                lineHeight={2.2}
              >
                送信完了後、担当者より通常2~3営業日以内に
                <Box as={`br`} />
                ご連絡させていただきます。
              </Text>
              <Text
                mb={8}
                px={{ base: 8, lg: 150 }}
                fontSize={12}
                lineHeight={2.4}
              >
                ・物件見学のご予約
                <Box as={`br`} />
                ・不動産売却のご相談
                <Box as={`br`} />
                ・サービスに関するお問い合わせ
                <Box as={`br`} />
                ・その他のご相談
              </Text>
              <Text
                fontWeight="bold"
                fontSize={14}
                textAlign="center"
                lineHeight={2.6}
              >
                お問い合わせは、お電話でも承ります。
                <Box as={`br`} display={{ base: "block", lg: "none" }} />
                お気軽にご連絡ください。
              </Text>
              <Text
                mb={{ base: 0, lg: 10 }}
                fontWeight="bold"
                fontSize={{ base: 24, lg: 24 }}
                textAlign="center"
                mt={4}
              >
                <Stack direction={`row`} justify="center" align="center">
                  <BsFillTelephoneFill />
                  <Link href="tel:+81-558-64-8880" letterSpacing={2.2}>
                    {tel}
                  </Link>
                </Stack>
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Box
                as={`form`}
                onSubmit={handleSubmit(onSubmit)}
                w={{ base: "100%", lg: `500px` }}
                p={{ base: 4, lg: 0 }}
              >
                <FormControl isInvalid={errors.name} mt={6} mb={6}>
                  <FormLabel
                    htmlFor="name"
                    color={errors.name ? "red.500" : "gray.700"}
                  >
                    お名前{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"red"}
                      fontWeight={`bold`}
                      rounded={0}
                    >
                      必須
                    </Tag>
                  </FormLabel>
                  <Input
                    id="name"
                    placeholder="例）伊豆太郎"
                    fontSize={14}
                    {...register("name", {
                      required: "必須入力です",
                    })}
                    _placeholder={{
                      color: errors.name ? "red.500" : "gray.500",
                    }}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.tel} mt={6} mb={6}>
                  <FormLabel
                    htmlFor="tel"
                    color={errors.tel ? "red.500" : "gray.700"}
                  >
                    電話番号{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"red"}
                      fontWeight={`bold`}
                      rounded={0}
                    >
                      必須
                    </Tag>
                  </FormLabel>
                  <Input
                    id="tel"
                    placeholder="例）09012345678"
                    fontSize={14}
                    {...register("tel", {
                      required: "必須入力です",
                    })}
                    _placeholder={{
                      color: errors.tel ? "red.500" : "gray.500",
                    }}
                  />
                  <FormErrorMessage>
                    {errors.tel && errors.tel.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.email} mt={6} mb={6}>
                  <FormLabel
                    htmlFor="email"
                    color={errors.email ? "red.500" : "gray.700"}
                  >
                    メールアドレス{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"gray"}
                      fontWeight={`bold`}
                      rounded={0}
                    >
                      任意
                    </Tag>
                  </FormLabel>
                  <Input
                    id="email"
                    placeholder="例）saifarm@example.com"
                    fontSize={14}
                    {...register("email", {
                      // required: "必須入力です",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "不正なメールフォーマットです",
                      },
                    })}
                    _placeholder={{
                      color: errors.email ? "red.500" : "gray.500",
                    }}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.message}>
                  <FormLabel
                    htmlFor="message"
                    color={errors.email ? "red.500" : "gray.700"}
                  >
                    お問い合わせ内容{" "}
                    <Tag
                      size={`sm`}
                      variant={"outline"}
                      colorScheme={"red"}
                      fontWeight={`bold`}
                      rounded={0}
                    >
                      必須
                    </Tag>
                  </FormLabel>
                  <Textarea
                    id="message"
                    fontSize={14}
                    h={`200px`}
                    placeholder="こちらにお問い合わせ内容をご入力ください"
                    {...register("message", {
                      required: "必須入力です",
                    })}
                    _placeholder={{
                      color: errors.message ? "red.500" : "gray.500",
                    }}
                  />
                  <FormErrorMessage>
                    {errors.message && errors.message.message}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  as={motion.button}
                  position={`relative`}
                  fontSize={12}
                  animate={btnControls}
                  initial={{
                    backgroundColor: "#000",
                    color: "white",
                  }}
                  onHoverStart={() => {
                    btnControls
                      .start({
                        backgroundColor: "#f0f0f0",
                        color: "#000",
                        transition: { duration: 0.3 },
                      })
                      .then(() => {
                        borderControls.start({
                          width: "100%",
                          transition: { duration: 0.5 },
                        })
                      })
                  }}
                  onHoverEnd={() => {
                    btnControls
                      .start({ backgroundColor: "#000", color: "white" })
                      .then(() => {
                        borderControls.start({ width: 0 })
                      })
                  }}
                  mt={{ base: 4, lg: 12 }}
                  rounded={0}
                  isLoading={isSubmitting}
                  size={`sm`}
                  type="submit"
                >
                  <Stack>
                    <Text>送信</Text>
                    <Box
                      as={motion.div}
                      animate={borderControls}
                      position={`absolute`}
                      borderBottom={`2px`}
                      borderColor={`black`}
                      zIndex={75}
                      bottom={0}
                      left={0}
                    />
                  </Stack>
                </Button>
              </Box>
            </GridItem>
          </Grid>
        </Stack>
      </Container>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  {
    allContentfulSiteImages(filter: { imageNo: { eq: 21 } }, limit: 1) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
    allContentfulCompanyProfile {
      edges {
        node {
          tel
        }
      }
    }
  }
`
